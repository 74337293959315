import React from 'react';
import solutions from '../assets/demo.jpg';
import '../styles/SolutionView.scss';

function SolutionView (props){

	

	return(
		<div className="solutions">
			<div id="solutionsHeader">
				<h1>Qualitative, User-Driven Development Solutions</h1>
				<h3><i>"Good technology delivers, great technology inspires."</i></h3>
			</div>
			<div className="solutionsDiv">
				<div id="textDiv">
					<p id="solutionsText">The most ubiquitous technologies are the ones that we should never see. The ones that are so obvious that they are inevitable. We are so astonished when they finally come into being, because we cannot fathom how we got along without them. Good technology merely delivers, great technology inspires.<br /><br />
					   The distance between the two is made up of just one thing… people. By embedding ourselves into the lives of our users, we can participate in their world, perceive their experience of it, and by seeing what is present we can discover what could be. Within that liminality lies innovation. <br /><br />
					   Through the application of ethnographic research methods, quantitative analysis from qualitative user-data, and collaborative design in partnership with our clients we can discover their users' fundamental needs and how to translate those needs into leading edge solutions.</p>
				</div>
				<div id="imgDiv">	
					<img src={solutions} alt="App Demo" id="solutionsImg"/>
				</div>
			</div>

		</div>
	)
}

export default SolutionView;