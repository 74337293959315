import React 		from 'react';
import html 		from '../assets/html5.png';
import css 			from '../assets/css.png';
import sass 		from '../assets/sass.png';
import javascript 	from '../assets/javascript.png';
import jquery 		from '../assets/jquery.png';
import express 		from '../assets/express.png';
import node 		from '../assets/nodejs.png';
import reactJs 		from '../assets/reactjs.jpg';
import reactNative 	from '../assets/reactnative.png';
import expo 		from '../assets/expo.png';
import backbone 	from '../assets/backbonejs.png';
import marionette 	from '../assets/marionette.png';
import stickIt 		from '../assets/backbonestickit.png';
import linux 		from '../assets/linux.jpg';
import linode 		from '../assets/linode.jpg';
import docker 		from '../assets/docker.jpg';
import bootstrap 	from '../assets/bootstrap.png';
import materialize 	from '../assets/materialize.png';
import ajax 		from '../assets/ajax.png';
import webpack 		from '../assets/webpack.png';
import nightwatch 	from '../assets/nightwatch.png';
import git 			from '../assets/github.svg';
import bitbucket 	from '../assets/bitbucket.png';
import pipelines 	from '../assets/bitbucketpipelines.png';
import mariaDB 		from '../assets/mariadb.png';
import postgres 	from '../assets/postgresql.png';

import '../styles/SkillView.scss';

function SkillView (props){

	

	return(
		<div className="skills">
			<h1>Skills</h1>
			<div className="skillsDiv">
					<div className="skillDiv">
						<img src={html} alt="html" className="skillImg" />
						<p className="skillName">HTML</p>
					</div>
					<div className="skillDiv">
						<img src={css} alt="css" className="skillImg" />
						<p className="skillName">CSS</p>
					</div>
					<div className="skillDiv">
						<img src={sass} alt="sass" className="skillImg" />
						<p className="skillName">SASS</p>
					</div>
					<div className="skillDiv">
						<img src={javascript} alt="" className="skillImg" />
						<p className="skillName">JavaScript</p>
					</div>
					<div className="skillDiv">
						<img src={jquery} alt="jquery" className="skillImg" />
						<p className="skillName">jQuery</p>
					</div>
					<div className="skillDiv">
						<img src={express} alt="express" className="skillImg" />
						<p className="skillName">Express</p>
					</div>
					<div className="skillDiv">
						<img src={node} alt="node" className="skillImg" />
						<p className="skillName">NodeJS</p>
					</div>
					<div className="skillDiv">
						<img src={reactJs} alt="reactJs" className="skillImg" />
						<p className="skillName">ReactJS</p>
					</div>
					<div className="skillDiv">
						<img src={reactNative} alt="react native" className="skillImg" />
						<p className="skillName">React Native</p>
					</div>
					<div className="skillDiv">
						<img src={expo} alt="expo" className="skillImg" />
						<p className="skillName">Expo</p>
					</div>
					<div className="skillDiv">
						<img src={backbone} alt="backbone" className="skillImg" />
						<p className="skillName">Backbone</p>
					</div>
					<div className="skillDiv">
						<img src={marionette} alt="marionette" className="skillImg" />
						<p className="skillName">Marionette</p>
					</div>
					<div className="skillDiv">
						<img src={stickIt} alt="backbone stickit" className="skillImg" />
						<p className="skillName">Backbone StickIt</p>
					</div>
					<div className="skillDiv">
						<img src={linux} alt="linux" className="skillImg" />
						<p className="skillName">Linux</p>
					</div>
					<div className="skillDiv">
						<img src={linode} alt="linode" className="skillImg" />
						<p className="skillName">Linode</p>
					</div>
					<div className="skillDiv">
						<img src={docker} alt="docker" className="skillImg" />
						<p className="skillName">Docker</p>
					</div>
					<div className="skillDiv">
						<img src={bootstrap} alt="bootstrap" className="skillImg" />
						<p className="skillName">Bootstrap</p>
					</div>
					<div className="skillDiv">
						<img src={materialize} alt="materialize" className="skillImg" />
						<p className="skillName">Materialize</p>
					</div>
					<div className="skillDiv">
						<img src={ajax} alt="ajax" className="skillImg" />
						<p className="skillName">Ajax</p>
					</div>
					<div className="skillDiv">
						<img src={webpack} alt="webpack" className="skillImg" />
						<p className="skillName">Webpack</p>
					</div>
					<div className="skillDiv">
						<img src={nightwatch} alt="nightwatch" className="skillImg" />
						<p className="skillName">Nightwatch</p>
					</div>
					<div className="skillDiv">
						<img src={git} alt="git" className="skillImg" />
						<p className="skillName">Git</p>
					</div>
					<div className="skillDiv">
						<img src={bitbucket} alt="bitbucket" className="skillImg" />
						<p className="skillName">Bitbucket</p>
					</div>
					<div className="skillDiv">
						<img src={pipelines} alt="bitbucket pipelines" className="skillImg" />
						<p className="skillName">Bitbucket Pipelines</p>
					</div>
					<div className="skillDiv">
						<img src={mariaDB} alt="maria db" className="skillImg" />
						<p className="skillName">MariaDB</p>
					</div>
					<div className="skillDiv">
						<img src={postgres} alt="postgres sql" className="skillImg" />
						<p className="skillName">PostgresSQL</p>
					</div>
			</div>
		</div>
	)
}

export default SkillView;