import React 			from 'react';
import transactionPdf 	from '../assets/transaction.pdf';

import '../styles/ProjectView.scss';

function ProjectView (props){

	

	return(
		<div className="project">
			<h1>Projects</h1>
			<div id="projectsDiv">
				<div className="projectDiv">
					<div className="projectSec" id="hammerdogSec">
						<p className="projectDescription" id="hammerdogDesc">
							A feature-rich, dynamically-rendered e-commerce site includes a custom CMS for user-friendly content management, Paypal integration, custom shopping platform, backend server with MySQL, and is served on a Linux-based VPS.<br /><br />
							The mobile-first design is rooted in the company's branding while taking cues from the tabletop products that enthrall their customer base, with a UX that streamlines the shopping experience across all their product lines.<br /><br />
							<i>Built with HTML, SASS, JavaScript, NodeJS, ReactJS, React Router, React Bootstrap, ExpressJS, Paypal API, TwitterAPI, Linux, Docker, MariaDB, Serve, XBBCode, and Git</i><br /><br />
						</p>
						
					</div>
					<p className="projectName">Hammerdog Games</p>
						
				</div>
				<div className="projectDiv">
					<div className="projectSec" id="jackboxSec">
						<p className="projectDescription">
							Developed unique mobile-based controllers for several games in Jackbox's new Party Pack, based on mock-ups and instructions from in-house artists and game designers. Additionally added features and bug fixes to existing controllers and controller environment and redesigned and implemented post game sharing for games using React.<br /><br />
							Included taking lead on incorporating, designing, developing and deploying Unit, Integration, and E2E testing into our controller environment using Nightwatch and Selenium. Also contributed to the implementation of Webpack bundling for better dependency and asset management.<br /><br />
							<i>Built with HMTL, CSS Javascript, jQuery, Bootstrap, Backbone.js, Marionette.js, ReactJS, NightwatchJS and Selenium</i><br /><br />
							<a href="https://www.jackboxgames.com/party-pack-five/" target="_blank" rel="noopener noreferrer">Play the Jackbox Party Pack 5</a>
						</p>
						
					</div>
					<p className="projectName">Jackbox Party Pack 5</p>
						
				</div>
				<div className="projectDiv">
					<div className="projectSec" id="loaSec">
						<p className="projectDescription">
							A movie trivia game, with a sardonic sensibility, that tests players’ knowledge of movie quotes.<br /><br />
							<i>Built with HTML, CSS, Javascript, jQuery, AJAX, Node</i><br /><br />
							<a href="http://www.lawofattribution.com" target="_blank" rel="noopener noreferrer">Play Law of Attribution</a>
						</p>
						
					</div>
					<p className="projectName">Law of Attribution</p>
				</div>
				<div className="projectDiv">
					<div className="projectSec" id="transactionSec">
						<p className="projectDescription">
							A panic button app that helps bridge the resource gap for trans americans who face a constant threat of violence and discrimination, both in their communities and when interacting with vital services in the medical and legal systems.<br /><br />
							<i>Built with React-Native, Expo, Javascript, Ruby, Rails, Sessions, Fetch, PostgresSQL</i><br /><br />
							<a href={transactionPdf} target="_blank" rel="noopener noreferrer">Learn More About TransAction</a>
						</p>
						
					</div>
					<p className="projectName">TransAction</p>
				</div>
			</div>
		</div>
	)
}

export default ProjectView;