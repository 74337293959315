import React from 'react';
import about from '../assets/about.jpg'
import '../styles/AboutView.scss';

function AboutView (props){

	

	return(
		<div className="about">
			<h1>About Myka</h1>
			<div className="aboutDiv">
				<div id="imageDiv">
					<img src={about} alt="" id="aboutImg" />
				</div>
				<div id="textDiv">
					<p id="aboutText">Myka is able to take disparate threads and weave them into a dynamic, larger tapestry. Her own life being an example. From her experience in development, the performing arts, cultural anthropology and applied ethnographic research, to years of working in social development and transformation.<br /><br />
										 Web development is where she bring all these skills to bear to deliver a qualitative approach to web design and development that delivers exactly what the client needs, with aesthetics that are smooth, engaging and that positively impact the most users possible.<br /><br />
										 Myka believes in the power of the web to bring every tool imaginable to those who need them most. She wants to make the web a place that whether site, application, or portal, the user is delivered a product that informs, engages, empowers and entertains.</p>
				</div>
			</div>
		</div>
	)
}

export default AboutView;