import React, { useEffect, useState } 	from 'react';
import { Container, Row } 				from 'react-bootstrap';
import 							 			 '../styles/SplashView.scss';

function SplashView (props){

	const [ myka, setMyka ] = useState('grounded');
	const [ tech, setTech ] = useState('data');

	useEffect(() => {
		generateListItems();
		// eslint-disable-next-line
	}, [])

	useEffect(() => {

		const logoSwap = setTimeout(() => {

			if(myka === 'grounded') {
				setMyka(myka => 'invested');
				setTech(tech => 'people');
			} else if (myka === 'invested') {
				setMyka(myka => 'myka');
				setTech(tech => 'tech');
			} else {
				setMyka(myka => 'grounded');
				setTech(tech => 'data');
			}

		}, 3000);

		return () => clearTimeout(logoSwap)

	}, [myka])

	const generateListItems = () => {
		
		for (let i = 1; i <= 8; i++) {

			const lineArray = generateLineArray();
			const lineId 	= "line" + i.toString();
			// eslint-disable-next-line
			lineArray.map((number, index) => {

				const blinkValue 	= Math.floor(Math.random() * 20);
				const blinkClass 	= "blink" + blinkValue.toString()
				const lineItem 		= document.createElement("li");

				lineItem.className 	= "lineLi " + blinkClass;
				lineItem.innerHTML 	= number;
				
				document.getElementById(lineId).appendChild(lineItem)
			})

		}
	}

	const generateLineArray = () => {

		let line = []

		for (let i = 1; i <= 131; i++) {
			line.push(Math.floor((Math.random() * 2)))
		}
		
		return line
	}

	return(
		<div className="splash">
			<Container>
				<Row className="binary">
					<div className="line">
						<ul className="lineUl" id="line1"></ul>
					</div>
					<div className="line">
						<ul className="lineUl" id="line2"></ul>
					</div>
					<div className="line">
						<ul className="lineUl" id="line3"></ul>
					</div>
					<div className="line">
						<ul className="lineUl" id="line4"></ul>
					</div>
				</Row>
				<Row id="brandLine">
					<span className="brand" id="myka">{ myka }</span>
					<span className="brand" id="in">in</span>
					<span className="brand" id="tech">{ tech }</span>
				</Row>
				<Row className="binary">
					<div className="line">
						<ul className="lineUl" id="line5"></ul>
					</div>
					<div className="line">
						<ul className="lineUl" id="line6"></ul>
					</div>
					<div className="line">
						<ul className="lineUl" id="line7"></ul>
					</div>
					<div className="line">
						<ul className="lineUl" id="line8"></ul>
					</div>
				</Row>
			</Container>
		</div>
	)
}

export default SplashView;