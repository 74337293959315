import React, { Dropdown, DropdownItem, DropdownMenu, DropdownToggle }  from 'react';
import { Link, Route, BrowserRouter as Router, Routes }   from 'react-router-dom';
import { Nav, Navbar, NavDropdown, NavLink }              from 'react-bootstrap';
import AboutView                                          from './views/AboutView'
import ContactView                                        from './views/ContactView'
import ProjectView                                        from './views/ProjectView'
import SkillView                                          from './views/SkillView'
import SolutionView                                       from './views/SolutionView'
import SplashView                                         from './views/SplashView'
import logo                                               from './logo.png';
import menu                                               from './assets/menu.png';
import resume                                             from './assets/myka_martinez_resume.pdf'
import                                                         './App.scss';

function App() {

  return (
    <Router>
      <div className="App">
        <header className="header">
          <div id="menu">
            <Link to='/'><img src={ logo } id="logo" alt="mykaInTech logo"/></Link>
            <Link to='/projects'><p className="menuItem">Projects</p></Link>
            <Link to='/skills'><p className="menuItem">Skills</p></Link>
            <Link to='/solutions'><p className="menuItem">Solutions</p></Link>
            <Link to='/about'><p className="menuItem">About</p></Link>
            <Link to='/contact'><p className="menuItem">Contact</p></Link>
            <a href={ resume } target="_blank" rel="noopener noreferrer"><p className="menuItem">Resume</p></a>
          </div>
          <Navbar sticky="top" id="navbar" className="bs-navbar-collapse" collapseOnSelect bg="light" expand="lg">
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto">
                <NavDropdown title={<img src={menu} alt="menu icon"  />}id="basic-nav-dropdown">
                  <div className="navDiv">
                   <NavDropdown.Item href="/" className="navLink">Home</NavDropdown.Item><br /><br />
                    <NavDropdown.Item href="http://www.mykaintech.com/projects" className="navLink">Projects</NavDropdown.Item><br /><br />
                    <NavDropdown.Item href="http://www.mykaintech.com/skills"  className="navLink">Skills</NavDropdown.Item><br /><br />
                    <NavDropdown.Item href="http://www.mykaintech.com/solutions"  className="navLink">Solutions</NavDropdown.Item><br /><br />
                    <NavDropdown.Item href="http://www.mykaintech.com/about"  className="navLink">About</NavDropdown.Item><br /><br />
                    <NavDropdown.Item href={ resume } target="_blank" rel="noopener noreferrer"  className="navLink">Resume</NavDropdown.Item>
                  </div>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </header>
        <Routes>
          <Route path="/"  exact element= { <SplashView /> }/>
          <Route path="/projects"  exact element= { <ProjectView /> }/>
          <Route path="/skills"  exact element= { <SkillView /> }/>
          <Route path="/solutions"  exact element= { <SolutionView /> }/>
          <Route path="/about"  exact element= { <AboutView /> }/>
          <Route path="/contact"  exact element= { <ContactView /> }/>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
