import React 	from 'react';
import email 	from '../assets/email.png';
import gitHub 	from '../assets/github.png';
import linkedIn from '../assets/linkedIn.png';
import '../styles/ContactView.scss';

function ContactView (props){

	

	return(
		<div className="contact">
			<h1>Contact Myka</h1>
			<div id="contactsDiv">
				<div className="contactDiv">
					<img src={email} alt="" className="contactImg" />
					<p className="contactText"><a href="mailto:myka@mykaintech.com"> myka@mykaintech.com</a></p>
				</div>
				<div className="contactDiv">
					<img src={gitHub} alt="" className="contactImg" />
					<p className="contactText"><a href="https://github.com/MykaMart">github/MykaMart</a></p>
				</div>
				<div className="contactDiv">
					<img src={linkedIn} alt="" className="contactImg" />
					<p className="contactText"><a href="https://www.linkedin.com/in/myka-martinez/">linkedin.com/in/myka-martinez/</a></p>
				</div>
			</div>
		</div>
	)
}

export default ContactView;